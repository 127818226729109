import gql from 'graphql-tag';

export const putDeviceEvent = /* GraphQL */ gql`
  mutation putDeviceEvent(
    $input: DeviceEventInput!
  ) {
    putDeviceEvent(input: $input) {
      deviceId
      clientId
      eventTime
      expiresAt
      event {
        version
        utc
        id
        infoCode
        packetCount
        powerVoltage
        digitalInputStatus
      }
      gnss {
        utc
        headingDegrees
        latitude
        longitude
        satelliteCount
        hdop
        speedKmh
        maxSpeedKmh
        trip
      }
      usage {
        runningTime
        acceleration
        digitalInput
        mainSupplyVoltage
      }
      cif {
        utc
        id
        data
        dataType
        parsedData
      }
    }
  }
`;

export const putDeviceUpdate = /* GraphQL */ gql`
  mutation putDeviceUpdate(
    $input: DeviceInput!
  ) {
    putDeviceUpdate(input: $input) {
      deviceId
      clientId
      connected
    }
  }
`;
