import gql from 'graphql-tag';
import { DeviceEvent, Device } from '../types';

export type GetQuickSightEmbedUrlResponse = { getQuicksightEmbedUrl?: string };
export const getQuicksightEmbedUrl = gql`
  query getQuicksightEmbedUrl(
    $jwtToken: String!,
    $payloadSub: String!,
    $email: String!,
    $dashboardId: String!
  ) {
    getQuicksightEmbedUrl(jwtToken: $jwtToken, payloadSub: $payloadSub, email: $email, dashboardId: $dashboardId)
  }
`;

export type ListDevicesResponse = { listDevices?: Device[] };
export const listDevices = /* GraphQL */ gql`
  query listDevices {
    listDevices {
      deviceId
      clientId
      connected
    }
  }
`;

export type GetDeviceResponse = { getDevice?: Device };
export const getDevice = /* GraphQL */ gql`
  query getDevice($deviceId: ID!) {
    getDevice(deviceId: $deviceId) {
      deviceId
      clientId
      connected
    }
  }
`;

export type GetDeviceEventsResponse = { getDeviceEvents?: DeviceEvent[] };
export const getDeviceEvents = /* GraphQL */ gql`
  query getDeviceEvents(
    $deviceId: ID!
  ) {
    getDeviceEvents(deviceId: $deviceId) {
      deviceId
      clientId
      eventTime
      expiresAt
      event {
        version
        utc
        id
        infoCode
        packetCount
        powerVoltage
        digitalInputStatus
      }
      gnss {
        utc
        headingDegrees
        latitude
        longitude
        satelliteCount
        hdop
        speedKmh
        maxSpeedKmh
        trip
      }
      usage {
        runningTime
        acceleration
        digitalInput
        mainSupplyVoltage
      }
      cif {
        utc
        id
        data
        dataType
        parsedData
      }
    }
  }
`;

export type GetNewestDeviceEventResponse = { getNewestDeviceEvent: DeviceEvent[] | null };
export const getNewestDeviceEvent = /* GraphQL */ gql`
query getNewestDeviceEvent(
  $deviceId: ID!
  $fieldName: String,
  $limit: Int,
  $maxQueries: Int
) {
  getNewestDeviceEvent(deviceId: $deviceId, fieldName: $fieldName, limit: $limit, maxQueries: $maxQueries) {
    deviceId
    clientId
    eventTime
    expiresAt
    event {
      version
      utc
      id
      infoCode
      packetCount
      powerVoltage
      digitalInputStatus
    }
    gnss {
      utc
      headingDegrees
      latitude
      longitude
      satelliteCount
      hdop
      speedKmh
      maxSpeedKmh
      trip
    }
    usage {
      runningTime
      acceleration
      digitalInput
      mainSupplyVoltage
    }
    cif {
      utc
      id
      data
      dataType
      parsedData
    }
  }
}
`;

export type ListDeviceEventsResponse = { listDeviceEvents?: DeviceEvent[] };
export const listDeviceEvents = /* GraphQL */ gql`
  query listDeviceEvents(
    $filter: ModelDeviceEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        deviceId
        clientId
        eventTime
        expiresAt
        event {
          version
          utc
          id
          infoCode
          packetCount
          powerVoltage
          digitalInputStatus
        }
        gnss {
          utc
          headingDegrees
          latitude
          longitude
          satelliteCount
          hdop
          speedKmh
          maxSpeedKmh
          trip
        }
        usage {
          runningTime
          acceleration
          digitalInput
          mainSupplyVoltage
        }
        cif {
          utc
          id
          data
          dataType
          parsedData
        }
      }
      nextToken
    }
  }
`;
