import { queries } from "@aplicom-dashboard/common/graphql";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";

export const GetQuicksightDashboard = async (
  dashboardId: string
): Promise<string | undefined> => {
  try {
    const auth = await Auth.currentSession();
    const idToken = auth.getIdToken();
    const { sub, email } = idToken.decodePayload();

    const appsyncRes = (await API.graphql(
      graphqlOperation(queries.getQuicksightEmbedUrl, {
        jwtToken: idToken.getJwtToken(),
        payloadSub: sub,
        email,
        dashboardId
      })
    )) as GraphQLResult<queries.GetQuickSightEmbedUrlResponse>;

    return appsyncRes.data?.getQuicksightEmbedUrl;
  } catch (error) {
    throw error;
  }
};
