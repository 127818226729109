import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Container,
  ColumnLayout,
  Spinner,
  Header,
  Select,
  SelectProps,
} from "@awsui/components-react";
import { DeviceEvent, DeviceEventGnss } from "@aplicom-dashboard/common/types";
import { getGnssKeyDisplayValue } from "../../utils/DisplayUtils";

interface IProps {
  value: DeviceEvent | null | undefined;
}

type DeviceEventGnssStringKey = keyof DeviceEventGnss & string;

const applyMathFunctionToObjectListKey = (
  values: DeviceEventGnss[],
  key: DeviceEventGnssStringKey,
  mathFunc: (...values: number[]) => number
) => {
  if (values.length === 0) return 0;
  const v = values.map((value) => value[key]);
  return mathFunc(...v);
};
const arraySum = (...values: number[]) =>
  values.reduce((agg, val: number) => agg + val, 0);
const arrayAvg = (...values: number[]) => arraySum(...values) / values.length;

const selectableKeys: DeviceEventGnssStringKey[] = [
  "speedKmh",
  "maxSpeedKmh",
  "hdop",
  "satelliteCount",
];

const selectOptions: SelectProps.Option[] = selectableKeys.map((key) => ({
  value: String(key),
  label: getGnssKeyDisplayValue(key)
}));

const DeviceEventValuePanel: React.FC<IProps> = ({ value }: IProps) => {
  const values = useRef<DeviceEventGnss[]>([]);
  const [selectedKey, setSelectedKey] =
    useState<DeviceEventGnssStringKey>("satelliteCount");

  const min = useMemo(
    () =>
      applyMathFunctionToObjectListKey(values.current, selectedKey, Math.min),
    [values, selectedKey]
  );
  const max = useMemo(
    () =>
      applyMathFunctionToObjectListKey(values.current, selectedKey, Math.max),
    [values, selectedKey]
  );
  const avg = useMemo(
    () =>
      applyMathFunctionToObjectListKey(values.current, selectedKey, arrayAvg),
    [values, selectedKey]
  );
  const current =
    values.current.length === 0 ? 0 : values.current[values.current.length - 1][selectedKey];

  useEffect(() => {
    if (value?.gnss) values.current = [...values.current, value.gnss];
  }, [value]);

  return (
    <Container
      header={
        <Header variant="h3">
          Live statistics for metric
          <Select
            selectedOption={
              selectOptions.find((opt) => opt.value === selectedKey) ?? null
            }
            expandToViewport
            options={selectOptions}
            onChange={(event) => {
              if(!event.detail.selectedOption.value)
                return;
              
              setSelectedKey(
                event.detail.selectedOption.value as DeviceEventGnssStringKey
              )
            }}
          />
        </Header>
      }
    >
      <ColumnLayout columns={4} variant="text-grid">
        <div>
          <Box
            margin={{ bottom: "xxxs" }}
            color="text-label"
            fontSize="heading-s"
          >
            Current Value
          </Box>
          <Box
            margin={{ bottom: "xxxs" }}
            color="text-label"
            textAlign="center"
            fontSize="heading-xl"
          >
            {!value && <Spinner size="normal" />}
            {value && current}
          </Box>
        </div>
        <div>
          <Box
            margin={{ bottom: "xxxs" }}
            color="text-label"
            fontSize="heading-s"
          >
            Min
          </Box>
          <Box
            margin={{ bottom: "xxxs" }}
            color="text-label"
            textAlign="center"
            fontSize="heading-xl"
          >
            {!value && <Spinner size="normal" />}
            {value && min}
          </Box>
        </div>
        <div>
          <Box
            margin={{ bottom: "xxxs" }}
            color="text-label"
            fontSize="heading-s"
          >
            Max
          </Box>
          <Box
            margin={{ bottom: "xxxs" }}
            color="text-label"
            textAlign="center"
            fontSize="heading-xl"
          >
            {!value && <Spinner size="normal" />}
            {value && max}
          </Box>
        </div>
        <div>
          <Box
            margin={{ bottom: "xxxs" }}
            color="text-label"
            fontSize="heading-s"
          >
            Avg
          </Box>
          <Box
            margin={{ bottom: "xxxs" }}
            color="text-label"
            textAlign="center"
            fontSize="heading-xl"
          >
            {!value && <Spinner size="normal" />}
            {value && avg.toFixed(2)}
          </Box>
        </div>
      </ColumnLayout>
    </Container>
  );
};

export default DeviceEventValuePanel;
