import { DeviceEvent, DeviceEventGnss } from "@aplicom-dashboard/common/types";
import { Header, Table } from "@awsui/components-react";
import {
  getGnssDisplayValue,
} from "../../utils/DisplayUtils";

const GeoTable: React.FC<{ latestGnssEvent?: DeviceEvent }> = ({
  latestGnssEvent,
}) => {
  if (!latestGnssEvent || !latestGnssEvent.gnss) return null;

  const displayedGnssKeys: (keyof DeviceEventGnss)[] = [
    "utc",
    "satelliteCount",
    "hdop",
    "speedKmh",
    "maxSpeedKmh",
  ];

  return (
    <div id="device-geo" className="device-info">
      <Header className="device-info-header" variant="h3">
        Geo
      </Header>
      <Table
        items={Object.keys(latestGnssEvent?.gnss ?? {})
          .filter((key): key is keyof DeviceEventGnss =>
            displayedGnssKeys.includes(key as keyof DeviceEventGnss)
          )
          .map((key) =>
            getGnssDisplayValue(key, latestGnssEvent.gnss![key] ?? "")
          )
          .filter((value): value is [string, string] => Boolean(value))}
        id="device-info-gnss"
        columnDefinitions={[
          {
            id: "key",
            header: "Key",
            cell: (i) => i[0],
          },
          {
            id: "value",
            header: "Value",
            cell: (i) => i[1],
          },
        ]}
      />
    </div>
  );
};

export default GeoTable;
