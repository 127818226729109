import cdkOutputs from './cdk-outputs.json';

const { identityPoolId, region, userPoolId, userPoolClientId, graphqlApiUrl, locationMap, locationMapStyle } = cdkOutputs.ClientStack;
export const loadCdkConfiguration = () => ({
  Auth: {
    identityPoolId,
    region,
    identityPoolRegion: region,
    userPoolId,
    userPoolWebClientId: userPoolClientId,
    mandatorySignIn: false,
    authenticationFlowType: "USER_PASSWORD_AUTH"
  },
  'aws_appsync_graphqlEndpoint': graphqlApiUrl,
  'aws_appsync_region': region,
  'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  geo: {
    AmazonLocationService: {
      maps: {
        items: {
          [locationMap]: {
            style: locationMapStyle
          }
        },
        default: locationMap
      },
      region
    }
  }
});

export const quicksightDashboards = {
  usageByDevice: 'd8ee9944-779d-44c8-b2b6-0d3a816d8b6c',
  fleetUsage: 'f4eafd16-543f-4810-a2c0-69374aa05b3c'
};
