import React, { useEffect, useRef } from "react";
import { Box, Container, LineChart } from "@awsui/components-react";
import Spinner from "@awsui/components-react/spinner";
import { DeviceEvent, DeviceEventGnss } from "@aplicom-dashboard/common/types";
import { getGnssKeyDisplayValue } from "../../utils/DisplayUtils";

interface IProps {
  value: DeviceEvent | null | undefined;
}

interface IChartValue {
  x: Date;
  y: DeviceEventGnss;
}

const chartKeys: (keyof DeviceEventGnss)[] = [ "speedKmh", "maxSpeedKmh", "hdop", "satelliteCount" ];

const DeviceChart: React.FC<IProps> = ({ value }: IProps) => {
  const values = useRef<Array<IChartValue>>([]);
  const startDate = useRef(new Date());
  const endDate = useRef(new Date());

  useEffect(() => {
    const x = value?.gnss ? new Date(value.gnss.utc * 1000) : new Date();

    if (value?.gnss) {
      if(startDate.current > x) { // Re-adjust chart when receiving an older event
        startDate.current = x;
        endDate.current = new Date(x.getTime() + 3 * 60000);
      }
      // if initial chart time range exceeds size - push out by 5 minues
      if (x >= endDate.current) {
        endDate.current = new Date(x.getTime() + 3 * 60000);
      }

      values.current = [...values.current, { x: x, y: value.gnss }];
    } else if(values.current.length === 0) {
      // initialize chart with a 5 minute time range
      startDate.current = x;
      endDate.current = new Date(x.getTime() + 3 * 60000);
    }
  }, [value]);

  return (
    <Container>
      <Box margin={{ bottom: "s", top: "s" }} padding="xxs">
        {!value && (
          <Box textAlign="center">
            <Spinner size="normal" />
          </Box>
        )}
        {value && (
          <LineChart
            series={
              chartKeys.map(key => ({
                title: getGnssKeyDisplayValue(key),
                type: "line",
                data: values.current.map(({ x, y }) => ({ x, y: (y[key] as number) }))
              }))}
            i18nStrings={{
              xTickFormatter: (e) => e.toLocaleTimeString("fi-FI"),
            }}
            yDomain={[0, 200]}
            xDomain={[startDate.current, endDate.current]}
            height={300}
            hideFilter
            xScaleType="time"
          />
        )}
      </Box>
    </Container>
  );
};

export default DeviceChart;
