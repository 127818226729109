import { DeviceEventGnss, FMS_Key } from "@aplicom-dashboard/common/types";
import moment from "moment";
import {
  AxleWeightMap,
  ParsedData,
  ParsedDataArray,
  TelltaleMap,
} from "../types/DeviceMapTypes";

export const numberToLocaleString = (decimal: number, precision = 2) =>
  decimal.toLocaleString("fi-FI", { maximumFractionDigits: precision });

export const getGnssKeyDisplayValue = (key: keyof DeviceEventGnss) => {
  switch (key) {
    case "utc":
      return "Location timestamp";
    case "speedKmh":
      return "Speed (km/h)";
    case "maxSpeedKmh":
      return "Max Speed (km/h)";
    case "satelliteCount":
      return "Satellite Count";
    case "hdop":
      return "HDOP";
    default:
      return "";
  }
};

export const getGnssDisplayValue = (
  key: keyof DeviceEventGnss,
  value: string | number
): [string, string] | undefined => {
  switch (key) {
    case "utc":
      return [getGnssKeyDisplayValue(key), formatDisplayValue(value, true)];
    case "speedKmh":
    case "maxSpeedKmh":
      return [getGnssKeyDisplayValue(key), `${formatDisplayValue(value)} km/h`];
    case "satelliteCount":
    case "hdop":
      return [getGnssKeyDisplayValue(key), formatDisplayValue(value, false, 0)];
    default:
      return undefined;
  }
};

export const formatDisplayValue = (
  value: string | number,
  formatTime = false,
  precision?: number
) => {
  if (formatTime && typeof value === "number")
    return moment.unix(value).format("DD.MM.YYYY HH:mm:ss");

  if (typeof value === "number") return numberToLocaleString(value, precision);

  return value;
};

export const getCifKeyDisplayValue = (key: FMS_Key) => {
  switch (key) {
    case "FUEL_LEVEL":
      return "Fuel level";
    case "SERVICE_INFORMATION":
      return "Distance until next service";
    case "ENGINE_COOLANT_TEMPERATURE":
      return "Engine coolant temperature";
    case "AXLE_WEIGHT":
      return "Axle weight";
    default:
      return "";
  }
};

export const getCifDisplayValue = (
  key: FMS_Key,
  data: ParsedData
): [string, string][] | undefined => {
  const parsedDataArray = data as ParsedDataArray;
  switch (key) {
    case "FUEL_LEVEL":
      return parsedDataArray.map((value, index) => [
        `${getCifKeyDisplayValue(key)}, tank ${index + 1}`,
        `${formatDisplayValue(value)} %`,
      ]);
    case "ENGINE_COOLANT_TEMPERATURE":
      return [
        [
          getCifKeyDisplayValue(key),
          `${formatDisplayValue(parsedDataArray[0])} \u00B0C`,
        ],
      ];
    case "SERVICE_INFORMATION":
      return [
        [
          getCifKeyDisplayValue(key),
          `${formatDisplayValue(parsedDataArray[0], false, 0)} km`,
        ],
      ];
    case "AXLE_WEIGHT":
      const axleWeightMap = data as AxleWeightMap;
      return Object.keys(axleWeightMap).map((axle) => [
        `${getCifKeyDisplayValue(key)} (axle ${axle})`,
        `${axleWeightMap[axle]} kg`,
      ]);
    case "TELL_TALE_LIGHTS":
      const telltaleMap = data as TelltaleMap;
      return Object.keys(telltaleMap).map((key) => [`Telltale: ${key}`, telltaleMap[key]]);
    default:
      return undefined;
  }
};
