import { Device, DeviceEvent } from "@aplicom-dashboard/common/types";
import { ExpandableSection, Header, Spinner } from "@awsui/components-react";
import { GeoJSONSource, Map } from "maplibre-gl";
import { createMap } from "maplibre-gl-js-amplify";
import { useEffect, useRef, useState } from "react";
import { GetDeviceMarker } from "../../api/Devices";
import "./index.css";

interface IProps {
  mostRecentEvent?: DeviceEvent;
  device?: Device;
  deviceRoute: GeoJSON.Position[];
}

const getRouteGeoJson = (
  route: GeoJSON.Position[] = []
): GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> => ({
  type: "Feature",
  properties: {},
  geometry: {
    type: "LineString",
    coordinates: route,
  },
});

const DeviceMap: React.FC<IProps> = ({
  mostRecentEvent,
  device,
  deviceRoute,
}) => {
  const mapRef = useRef();
  const [map, setMap] = useState<Map | undefined>(undefined);

  // Render map
  useEffect(() => {
    async function initializeMap() {
      try {
        const map = await createMap({
          container: mapRef.current ?? "device-map",
          center: [25.814902, 62.290345],
          zoom: 10,
          maxZoom: 15,
          dragPan: false,
          dragRotate: false,
        });
        map.on("load", () => {
          map.addLayer({
            id: "device-route",
            type: "line",
            source: {
              type: "geojson",
              data: getRouteGeoJson(),
            },
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#ffa500",
              "line-width": 3,
              "line-opacity": 0.75,
            },
          });

          setMap(map);
        });

        map.repaint = true;
      } catch (error) {
        console.error("Error rendering map", error);
      }
    }

    if (!map) initializeMap();

    return () => {
      if (map) {
        setMap(undefined);
        map?.remove();
        console.log("Map unloaded");
      }
    };
  }, [map]);

  useEffect(() => {
    if (!map || !deviceRoute) return;

    const source = map.getSource("device-route") as GeoJSONSource;

    if (!source) return;
    source.setData(getRouteGeoJson(deviceRoute));
  }, [map, deviceRoute]);

  useEffect(() => {
    if (!map || !device || !mostRecentEvent || !mostRecentEvent.gnss) return;

    const marker = GetDeviceMarker(mostRecentEvent, device).addTo(map);
    marker.togglePopup();

    map.flyTo({ center: marker.getLngLat() });

    const centeringListener = () => {
      map.flyTo({ center: marker.getLngLat() });
    };
    map.on("zoomend", centeringListener);

    return () => {
      marker.remove();
      map.off("zoomend", centeringListener);
    };
  }, [map, mostRecentEvent, device]);

  return (
    <ExpandableSection
      defaultExpanded
      variant="container"
      header={
        <Header
          variant="h2"
          description={
            !mostRecentEvent && <span className="no-events">No location</span>
          }
          actions={(!device || !mostRecentEvent) && <Spinner size="big" />}
        >
          Device location
        </Header>
      }
      className="device-map"
    >
      <div id="device-map" ref={mapRef.current}></div>
    </ExpandableSection>
  );
};

export default DeviceMap;
