import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { createMap } from "maplibre-gl-js-amplify";
import { Marker, Map } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import {
  GetDevices,
  GetDeviceMarker,
  GetNewestDeviceEvent,
  GetDevice,
} from "../../api/Devices";
import { API, graphqlOperation } from "aws-amplify";
import { subscriptions } from "@aplicom-dashboard/common/graphql";
import {
  Container,
  Box,
  Header,
  SpaceBetween,
  Grid,
  Spinner,
  Tabs,
} from "@awsui/components-react";
import "./index.css";
import { DeviceEvent, Device } from "@aplicom-dashboard/common/types";
import { DeviceListing } from "./DeviceListing";
import { QuicksightDashboardEmbed } from "../../components/QuicksightDashboardEmbed";
import { DeviceCifMap } from "../../types/DeviceMapTypes";
import { mapCifArrayToObject } from "../../utils/MappingUtils";
import DeviceInfo from "../../components/DeviceInfo";

const MapPage: React.FC = () => {
  const history = useHistory();
  const mapRef = useRef<HTMLDivElement>(null);

  const [devices, setDevices] = useState<Device[]>([]);
  const [deviceLatestEvent, setDeviceLatestEvent] = useState<
    Record<string, DeviceEvent>
  >({});
  const [deviceLatestCifData, setDeviceLatestCifData] = useState<DeviceCifMap>(
    {}
  );
  const [markers, setMarkers] = useState<Record<string, Marker>>({});
  const [map, setMap] = useState<Map | undefined>(undefined);
  const [focusedDevice, setFocusedDevice] = useState<string | undefined>(
    undefined
  );
  const [compactDeviceListing, setCompactDeviceListing] = useState(true);
  const [isInitLoading, setIsInitLoading] = useState(false);
  const [openView, setOpenView] = useState("live");

  const navigateToDevicePage = useCallback(
    (deviceId: string) => {
      history.push("/device/" + deviceId);
      console.log(deviceId + " clicked");
    },
    [history]
  );

  const focusedDeviceInfo = useMemo(
    () => devices.find((device) => device.deviceId === focusedDevice),
    [devices, focusedDevice]
  );

  useEffect(() => {
    if (!focusedDevice) return;

    const marker = markers[focusedDevice];
    if (!marker || !map) return;

    // Close all popups
    Object.values(markers).forEach((marker) => {
      if (marker.getPopup().isOpen()) marker.togglePopup();
    });

    marker.togglePopup();
    const centerMap = () => {
      map.flyTo({ center: marker.getLngLat() });
    };
    centerMap();

    map.on("zoomend", centerMap);
    return () => {
      map.off("zoomend", centerMap);
    };
  }, [map, markers, focusedDevice]);

  useEffect(() => {
    if (!map) return;

    const newMarkers: Record<string, Marker> = devices.reduce(
      (markerMap, device) => {
        const event = deviceLatestEvent[device.deviceId];

        if (!event || !event.gnss) return markerMap;

        const marker = GetDeviceMarker(
          event,
          device,
          navigateToDevicePage,
          (deviceId) => setFocusedDevice(deviceId)
        ).addTo(map);

        return {
          ...markerMap,
          [device.deviceId]: marker,
        };
      },
      {}
    );

    // Replace marker using functional update
    setMarkers((markers) => {
      const existingKeys = Object.keys(markers);
      const nonExistingKeys = Object.keys(newMarkers).filter(
        (key) => !existingKeys.includes(key)
      );

      const updatedMarkers = existingKeys.reduce((map, key) => {
        if (!Object.keys(newMarkers).includes(key))
          return {
            ...map,
            [key]: markers[key],
          };

        const oldMarker = markers[key];
        const isPopupOpen = oldMarker.getPopup().isOpen();
        oldMarker.remove();

        if (isPopupOpen) newMarkers[key].togglePopup();

        return {
          ...map,
          [key]: newMarkers[key],
        };
      }, {});

      return {
        ...updatedMarkers,
        ...nonExistingKeys.reduce(
          (markerMap, key) => ({
            ...markerMap,
            [key]: newMarkers[key],
          }),
          {}
        ),
      };
    });
  }, [
    map,
    devices,
    deviceLatestEvent,
    navigateToDevicePage,
    deviceLatestCifData,
  ]);

  useEffect(() => {
    // call api to get list of sensors and display them as markers on the map
    async function DisplayDevices(map: Map) {
      const response = await GetDevices();

      if (response) {
        console.log("Devices retrieved");
        setDevices(response);
        const mostRecentEvents = await Promise.all(
          response.map(async (device) => ({
            device,
            event: (await GetNewestDeviceEvent(device.deviceId, "gnss"))?.[0],
            cif: (await GetNewestDeviceEvent(device.deviceId, "cif"))?.[0]?.cif,
          }))
        );

        const mostRecentValidEvents = mostRecentEvents.filter(
          ({ event }) => !!event
        );

        setDeviceLatestEvent(
          mostRecentValidEvents.reduce((record, { event }) => {
            const deviceEvent = event as DeviceEvent;
            return {
              ...record,
              [deviceEvent.deviceId]: event,
            };
          }, {})
        );

        // Map cif events to state
        const cifData: DeviceCifMap = mostRecentEvents
          .filter(({ cif }) => !!cif)
          .reduce(
            (map, { device, cif }) => ({
              ...map,
              [device.deviceId]: mapCifArrayToObject(cif!),
            }),
            {}
          );
        setDeviceLatestCifData(cifData);

        if (mostRecentValidEvents.length === 0) return;
        const { event } = mostRecentValidEvents[0];
        if (!event?.gnss) return;
        const { longitude, latitude } = event.gnss;
        map.setCenter([longitude, latitude]);
      }
    }

    // configure and display the map
    async function initializeMap() {
      try {
        setIsInitLoading(true);
        const map = await createMap({
          container: mapRef.current ?? "map",
          center: [25.81472, 62.291108],
          zoom: 7,
          maxZoom: 15,
        });
        //draw map
        setMap(map);
        map.on("dragstart", () => setFocusedDevice(undefined));

        map.repaint = true;
        console.log("Map Rendered");
        await DisplayDevices(map);
        setIsInitLoading(false);
      } catch (error) {
        console.error("Error rendering map", error);
      }
    }

    if (!map) initializeMap();

    return () => {
      if (map) {
        setMap(undefined);
        map?.remove();
        console.log("Map unloaded");
      }
    };
  }, [navigateToDevicePage, map]);

  // Start subscription for device events
  useEffect(() => {
    async function handleDeviceEvent(
      response: subscriptions.AmplifySubscriptionResponse<subscriptions.OnCreateDeviceEventsResponse>
    ) {
      const event = response.value.data.onCreateDeviceEvents;
      if (!event || !map) return;
      console.log("Device events subscription value received", event);
      const { deviceId } = event;

      if (event.gnss) {
        setDeviceLatestEvent((deviceLatestEvent) => {
          if (
            Object.keys(deviceLatestEvent).includes(deviceId) &&
            deviceLatestEvent[deviceId].eventTime > event.eventTime
          ) {
            console.error(
              "Received older device gnss event compared to most recent, skipping."
            );
            return deviceLatestEvent;
          }

          return {
            ...deviceLatestEvent,
            [deviceId]: event,
          };
        });
      }

      if (event.cif) {
        setDeviceLatestCifData((deviceLatestCifData) => ({
          ...deviceLatestCifData,
          [deviceId]: mapCifArrayToObject(
            event.cif!,
            deviceLatestCifData[deviceId]
          ),
        }));
      }
    }

    const subscription = (
      API.graphql(
        graphqlOperation(subscriptions.onCreateDeviceEvents)
      ) as Record<string, any>
    ).subscribe({
      next: handleDeviceEvent,
      error: (error: any) => console.warn(error),
    });

    return () => {
      subscription.unsubscribe();
      console.log("Device event subscription cancelled");
    };
  }, [map]);

  // Detect and fetch new devices
  useEffect(() => {
    async function fetchDevices() {
      const knownDevices = devices.map((device) => device.deviceId);
      const unknownDevices = [
        ...Object.keys(deviceLatestEvent).filter(
          (deviceId) => !knownDevices.includes(deviceId)
        ),
        ...Object.keys(deviceLatestCifData).filter(
          (deviceId) => !knownDevices.includes(deviceId)
        ),
      ];
      if (unknownDevices.length === 0) return;

      const deviceInformation = await Promise.all(
        unknownDevices.map(async (deviceId) => await GetDevice(deviceId))
      );
      setDevices((devices) => [
        ...devices,
        ...deviceInformation.filter((device): device is Device => !!device),
      ]);
    }

    fetchDevices();
  }, [devices, deviceLatestEvent, deviceLatestCifData]);

  // Start subscription for device connections/disconnections
  useEffect(() => {
    async function handleDeviceUpdate(
      response: subscriptions.AmplifySubscriptionResponse<subscriptions.OnDeviceUpdatesResponse>
    ) {
      const data = response.value.data.onDeviceUpdates;
      if (!data) return;
      console.log("Device update received", data);

      setDevices((devices) => {
        let index = devices.findIndex((d) => d.deviceId === data?.deviceId);
        if (index !== -1) {
          const deviceList = [...devices];
          deviceList.splice(index, 1, data);
          return deviceList;
        }

        return [...devices, data];
      });
    }

    const subscription = (
      API.graphql(graphqlOperation(subscriptions.onDeviceUpdates)) as Record<
        string,
        any
      >
    ).subscribe({
      next: handleDeviceUpdate,
      error: (error: any) => console.warn(error),
    });

    return () => {
      subscription.unsubscribe();
      console.log("Device status update subscription cancelled");
    };
  }, []);

  const liveView = (
    <Grid
      gridDefinition={[
        {
          colspan: {
            xxs: 12,
            xs: 12,
            s: !compactDeviceListing ? 9 : 6,
            m: !compactDeviceListing ? 8 : 5,
            l: !compactDeviceListing ? 7 : 5,
            xl: !compactDeviceListing ? 6 : 4,
            default: 12,
          },
        },
        {
          colspan: {
            xxs: 12,
            xs: 12,
            s: !compactDeviceListing ? 3 : 6,
            m: !compactDeviceListing ? 4 : 7,
            l: !compactDeviceListing ? 5 : 7,
            xl: !compactDeviceListing ? 6 : 8,
            default: 12,
          },
        },
      ]}
    >
      <SpaceBetween size="m" id="device-info-column">
        <DeviceListing
          devices={devices}
          focusOnMap={(deviceId) => setFocusedDevice(deviceId)}
          goToDetails={navigateToDevicePage}
          compact={compactDeviceListing}
          clickCompact={() => setCompactDeviceListing(!compactDeviceListing)}
          deviceMostRecent={deviceLatestEvent}
          isLoading={isInitLoading}
        />
        <DeviceInfo
          device={focusedDeviceInfo}
          latestGnssEvent={
            focusedDevice ? deviceLatestEvent[focusedDevice] : undefined
          }
          latestCifEvent={
            focusedDevice ? deviceLatestCifData[focusedDevice] : undefined
          }
          showFocusText
        />
      </SpaceBetween>
      <Container
        header={
          <Header
            variant="h2"
            id="map-header"
            description={focusedDevice && `Focused on ${focusedDevice}`}
            actions={
              isInitLoading && (
                <Spinner className="loading-spinner" size="big" />
              )
            }
          >
            Map
          </Header>
        }
      >
        <div id="map" ref={mapRef} />
      </Container>
    </Grid>
  );

  const analyticsView = (
    <Container header={<Header variant="h2">Fleet statistics</Header>}>
      <QuicksightDashboardEmbed dashboardName="fleetUsage" />
    </Container>
  );

  return (
    <Box margin={{ bottom: "l", top: "s" }} padding="xxs">
      <SpaceBetween size="m">
        <Header variant="h1">Fleet view</Header>
        <div>
          <Tabs
            activeTabId={openView}
            className="page-tabs"
            onChange={(event) => setOpenView(event.detail.activeTabId)}
            tabs={[
              {
                id: "live",
                label: "Live Map",
              },
              {
                id: "analytics",
                label: "Analytics",
              },
            ]}
          />
          <div style={{ display: openView === "live" ? undefined : "none" }}>
            {liveView}
          </div>
          <div
            style={{ display: openView === "analytics" ? undefined : "none" }}
          >
            {analyticsView}
          </div>
        </div>
      </SpaceBetween>
    </Box>
  );
};

export default MapPage;
