import { FMS_Key } from "@aplicom-dashboard/common/types";
import { Header, Table } from "@awsui/components-react";
import { CifMapping } from "../../types/DeviceMapTypes";
import { getCifDisplayValue } from "../../utils/DisplayUtils";

const CifTable: React.FC<{ latestCifEvent?: CifMapping }> = ({
  latestCifEvent,
}) => {
  if (!latestCifEvent || Object.keys(latestCifEvent).length === 0) return null;

  const cifItems = Object.keys(latestCifEvent)
    .sort((a, b) => a.localeCompare(b)) // Sort keys alphabetically
    .map((key) =>
      // Get display values (tuple arrays) for each key
      getCifDisplayValue(
        key as FMS_Key,
        latestCifEvent[key as FMS_Key]!.parsedData
      )
    )
    .flat() // Flatten tuples into single top-level array
    .filter((value): value is [string, string] => Boolean(value)); // Filter undefined values

  return (
    <div id="device-cif" className="device-info">
      <Header className="device-info-header" variant="h3">FMS Data Values</Header>
      <Table
        items={cifItems}
        id="device-info-cif"
        columnDefinitions={[
          { id: "key", header: "Key", cell: ([key]) => key },
          { id: "value", header: "Value", cell: ([_key, value]) => value },
        ]}
      />
    </div>
  );
};

export default CifTable;
