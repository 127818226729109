import { API, graphqlOperation } from "aws-amplify";
import {
  DeviceEvent,
  Device
} from "@aplicom-dashboard/common/types";
import { queries } from "@aplicom-dashboard/common/graphql";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { Marker, Popup } from "maplibre-gl";
import moment from "moment";
import { Button, Header } from "@awsui/components-react";
import ReactDOM from "react-dom";

export const GetDevicePopup = (
  event: DeviceEvent,
  deviceDetails?: (deviceId: string) => void
) => {
  const popup = (
    <div className="map-popup">
      <Header
        variant="h2"
        description={`Latest data: ${moment
          .unix(event.eventTime)
          .format("DD.MM.YYYY HH:mm:ss")}`}
      >
        Device: {event.deviceId}
      </Header>
      <div className="popup-buttons">
        {deviceDetails && (
          <Button variant="primary" onClick={() => deviceDetails(event.deviceId)}>
            More details
          </Button>
        )}
      </div>
    </div>
  );

  // Render popup
  const placeholder = document.createElement("div");
  ReactDOM.render(popup, placeholder);
  const popupObject = new Popup({ offset: 10 }).setDOMContent(placeholder);

  return popupObject;
};

export const GetDeviceMarker = (
  event: DeviceEvent,
  device: Device,
  deviceDetails?: (deviceId: string) => void,
  setFocus?: (deviceId: string) => void
) => {
  if (!event.gnss) throw new Error("No GPS data for device marker");

  const color = device.connected ? "green" : "red";
  const marker = (
    <div
      id={`device-image-${event.deviceId}`}
      className="device"
      onClick={(event) => { event.stopPropagation(); setFocus && setFocus(device.deviceId); }}
      style={{
        backgroundColor: color,
        border: `0.1em solid ${color}`,
      }}
    ></div>
  );

  const element = document.createElement("div");
  ReactDOM.render(marker, element);

  const { longitude, latitude } = event.gnss;
  return new Marker(element, { rotation: event.gnss.headingDegrees })
    .setLngLat([longitude, latitude])
    .setPopup(GetDevicePopup(event, deviceDetails));
};

export const GetDevice = async (deviceId: string): Promise<Device | null> => {
  try {
    const response = (await API.graphql(
      graphqlOperation(queries.getDevice, { deviceId })
    )) as GraphQLResult<queries.GetDeviceResponse>;
    if (response?.data?.getDevice) {
      return response.data.getDevice;
    }

    return null;
  } catch (error) {
    throw error;
  }
};

export const GetDevices = async (): Promise<Device[]> => {
  try {
    const response = (await API.graphql(
      graphqlOperation(queries.listDevices)
    )) as GraphQLResult<queries.ListDevicesResponse>;
    if (response?.data?.listDevices) {
      return response.data.listDevices;
    }

    return [];
  } catch (error) {
    throw error;
  }
};

export const GetNewestDeviceEvent = async (
  deviceId: string,
  fieldName: string = "gnss",
  limit: number | null = null,
  maxQueries: number | null = null
) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(queries.getNewestDeviceEvent, {
        deviceId,
        fieldName,
        limit,
        maxQueries,
      })
    )) as GraphQLResult<queries.GetNewestDeviceEventResponse>;
    const result = response?.data?.getNewestDeviceEvent;
    return result ?? null;
  } catch (error) {
    throw error;
  }
};
