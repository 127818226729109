import { Device, DeviceEvent } from "@aplicom-dashboard/common/types";
import {
  Button,
  Grid,
  Header,
  Input,
  Select,
  Table,
} from "@awsui/components-react";
import { OptionDefinition } from "@awsui/components-react/internal/components/option/interfaces";
import { useMemo, useState } from "react";
import "./DeviceListing.css";

interface IDeviceListingProps {
  devices: Device[];
  focusOnMap: (deviceId: string) => void;
  goToDetails: (deviceId: string) => void;
  compact?: boolean;
  clickCompact?: () => void;
  deviceMostRecent: Record<string, DeviceEvent>;
  isLoading?: boolean;
}

export const DeviceListing = ({
  devices,
  focusOnMap,
  goToDetails,
  compact = false,
  clickCompact,
  deviceMostRecent,
  isLoading,
}: IDeviceListingProps): JSX.Element => {
  const [filterText, setFilterText] = useState("");
  const [connectedFilter, setConnectedFilter] =
    useState<OptionDefinition | null>(null);

  const visibleDevices = useMemo(() => {
    const connected = connectedFilter?.value;
    let result = devices;

    if (filterText)
      result = devices.filter(
        (device) =>
          device.clientId.includes(filterText) ||
          device.deviceId.includes(filterText)
      );

    if (connected === "connected")
      result = result.filter((device) => device.connected);
    else if (connected === "connected")
      result = result.filter((device) => !device.connected);

    return result;
  }, [devices, filterText, connectedFilter]);

  const deviceHasEvent = (deviceId: string) => {
    return Object.keys(deviceMostRecent).includes(deviceId);
  };

  return (
    <Table
      items={visibleDevices}
      id="device-listing"
      stickyHeader
      stickyHeaderVerticalOffset={0}
      wrapLines
      visibleColumns={compact ? ["deviceId", "actions"] : undefined}
      loading={isLoading}
      header={
        <Header
          variant="h2"
          actions={
            <Button
              onClick={clickCompact}
              variant="link"
              iconName={compact ? "treeview-expand" : "treeview-collapse"}
              iconAlign="right"
            >
              {compact ? "Expand" : "Collapse"}
            </Button>
          }
        >
          Devices
        </Header>
      }
      filter={
        <Grid
          gridDefinition={[
            { colspan: 8 },
            { colspan: 4 }
          ]}
        >
          <Input
            value={filterText}
            onChange={(event) => setFilterText(event.detail.value)}
            placeholder="Search devices"
            type="search"
          />
          <Select
            selectedOption={connectedFilter}
            onChange={(event) =>
              setConnectedFilter(event.detail.selectedOption.value ? event.detail.selectedOption : null)
            }
            options={[
              { value: undefined, label: "None" },
              { value: "connected", label: "Connected" },
              { value: "disconnected", label: "Disconnected" },
            ]}
            placeholder="Filter connection status"
            expandToViewport
          />
        </Grid>
      }
      columnDefinitions={[
        { id: "clientId", header: "Client Id", cell: (i) => i.clientId },
        {
          id: "deviceId",
          header: "Device Id / Name",
          cell: (i) => (
            <span
              className={
                !compact
                  ? undefined
                  : i.connected
                  ? "connected"
                  : "disconnected"
              }
            >
              {i.deviceId}
              {!deviceHasEvent(i.deviceId) && (
                <span className="no-events">No events</span>
              )}
            </span>
          ),
        },
        {
          id: "connected",
          header: "Status",
          cell: (i) =>
            i.connected ? (
              <span className="connected">Connected</span>
            ) : (
              <span className="disconnected">Disconnected</span>
            ),
        },
        {
          id: "actions",
          header: "Actions",
          cell: (i) => (
            <>
              <Button variant="primary" onClick={() => focusOnMap(i.deviceId)} disabled={!deviceHasEvent(i.deviceId)}>
                Focus
              </Button>
              <Button variant="link" onClick={() => goToDetails(i.deviceId)}>
                Details
              </Button>
            </>
          ),
        },
      ]}
    />
  );
};
