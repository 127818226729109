import { Device, DeviceEvent } from "@aplicom-dashboard/common/types";
import { ExpandableSection, Header, Spinner } from "@awsui/components-react";
import { CifMapping } from "../../types/DeviceMapTypes";
import GeoTable from "./GeoTable";
import CifTable from "./CifTable";
import "./index.css";
import { useState } from "react";

interface IProps {
  device?: Device;
  latestGnssEvent?: DeviceEvent;
  latestCifEvent?: CifMapping;
  showFocusText?: boolean;
  isLoading?: boolean;
}

const DeviceInfo: React.FC<IProps> = ({
  device,
  latestGnssEvent,
  latestCifEvent,
  showFocusText,
  isLoading
}) => {
  const [open, setOpen] = useState(true);

  return (
    <ExpandableSection
      id="device-details"
      disableContentPaddings
      variant="container"
      expanded={!!device && !!latestGnssEvent && open}
      onChange={() => setOpen(!open)}
      header={
        <Header
          variant="h2"
          description={
            device?.deviceId ??
            (showFocusText ? "No device focused" : "No data")
          }
          actions={isLoading && <Spinner size="big" />}
        >
          {device ? `Device details` : "No device details"}
        </Header>
      }
    >
      <div className="content">
        <GeoTable latestGnssEvent={latestGnssEvent} />
        <CifTable latestCifEvent={latestCifEvent} />
      </div>
    </ExpandableSection>
  );
};

export default DeviceInfo;
