import React from 'react';

import Logo from '../../assets/images/aplicom-logo.png'
import './index.css'

const AppBar: React.FC = () => {

    return (
        <div id='b'>
        <header id='h'>
            <a href="#/">
              <img src={Logo} height={28} alt="Aplicom" />
            </a>
        </header>
        </div>
    )
}

export default AppBar;