import React, { useEffect, useRef, useState } from "react";
import { GetQuicksightDashboard } from "../../api/Quicksight";
import { quicksightDashboards } from "../../configure";
import * as QuickSightEmbed from "amazon-quicksight-embedding-sdk";
import { Spinner } from "@awsui/components-react";

interface IProps {
  parameters?: Record<string, string>;
  dashboardName: keyof typeof quicksightDashboards;
}

export const QuicksightDashboardEmbed: React.FC<IProps> = ({
  parameters,
  dashboardName,
}) => {
  const embedContainerRef = useRef<HTMLDivElement>(null);
  const [embedLoading, setEmbedLoading] = useState(false);
  const [qsEmbed, setQsEmbed] =
    useState<QuickSightEmbed.EmbeddableObject | null>(null);

  useEffect(() => {
    async function setEmbed() {
      console.log("Embedding Quicksight dashboard");
      const embedUrl = await GetQuicksightDashboard(
        quicksightDashboards[dashboardName]
      );

      if (!embedUrl) return;
      const embed = QuickSightEmbed.embedDashboard({
        url: embedUrl,
        container: embedContainerRef.current ?? "",
        parameters,
        scrolling: "no",
        footerPaddingEnabled: true,
        height: "AutoFit",
        loadCallback: () => setEmbedLoading(false),
        errorCallback: (payload: Record<string, any>) => {
          setEmbedLoading(false);
          console.error('Failed to embed dashboard', payload);
        }
      });

      setQsEmbed(embed);
    }

    if (!qsEmbed && !embedLoading) {
      setEmbedLoading(true);
      setEmbed();
    } else if (qsEmbed && !embedLoading && parameters) {
      qsEmbed.setParameters(parameters);
    }
  }, [parameters, dashboardName, qsEmbed, embedLoading]);

  return (
    <div className="embedContainer">
      {embedLoading && <Spinner size="large" />}
      <div ref={embedContainerRef}></div>
    </div>
  );
};
