import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { GetDevice, GetNewestDeviceEvent } from "../../api/Devices";
import {
  Box,
  Header,
  SpaceBetween,
  BreadcrumbGroup,
  ExpandableSection,
  Grid,
  Tabs,
} from "@awsui/components-react";
import DeviceEventValuePanel from "../../components/DeviceValuePanel";
import DeviceChart from "../../components/DeviceChart";
import { subscriptions } from "@aplicom-dashboard/common/graphql";
import { DeviceEvent, Device } from "@aplicom-dashboard/common/types";
import { QuicksightDashboardEmbed } from "../../components/QuicksightDashboardEmbed";
import DeviceMap from "../../components/DeviceMap";
import "./index.css";
import { CifMapping } from "../../types/DeviceMapTypes";
import { mapCifArrayToObject } from "../../utils/MappingUtils";
import DeviceInfo from "../../components/DeviceInfo";

const DevicePage: React.FC = () => {
  const [device, setDevice] = useState<Device | undefined>(undefined);
  const [deviceRoute, setDeviceRoute] = useState<GeoJSON.Position[]>([]);
  const [latestGnssData, setLatestGnssData] = useState<DeviceEvent | undefined>(
    undefined
  );
  const [mostRecentEvent, setMostRecentEvent] = useState<
    DeviceEvent | undefined
  >(undefined);
  const [cifData, setCifData] = useState<CifMapping>({});
  const [isInitLoading, setIsInitLoading] = useState(false);
  const [openView, setOpenView] = useState("live");

  const { id } = useParams() as {
    id: string;
  };

  //fetch sensor to get name
  useEffect(() => {
    const initDevice = async () => {
      console.log("Fetching device");
      setIsInitLoading(true);
      try {
        const device = await GetDevice(id);
        if (!device) return;

        setDevice(device);
        console.log("Device retrieved");

        const gnssEvents = await GetNewestDeviceEvent(
          device.deviceId,
          "gnss",
          500,
          20
        );
        const latestGnssEvent = gnssEvents?.[0];
        if (latestGnssEvent && latestGnssEvent.gnss) {
          setMostRecentEvent(latestGnssEvent);
          setLatestGnssData(latestGnssEvent);
        }

        if (gnssEvents) {
          let maxCount = 0;
          setDeviceRoute(
            gnssEvents
              .reverse() // Result is in descending order, revert
              .reduce((arr: GeoJSON.Position[], { gnss, event }) => {
                if (maxCount < event.packetCount) {
                  maxCount = event.packetCount;
                  return [...arr, [gnss!.longitude, gnss!.latitude]];
                } else if (maxCount - event.packetCount < 10) {
                  return arr;
                }
                maxCount = 0;
                return [[gnss!.longitude, gnss!.latitude]];
              }, [])
          );
        }

        const newestCif = (
          await GetNewestDeviceEvent(device.deviceId, "cif")
        )?.[0];
        if (newestCif?.cif) setCifData(mapCifArrayToObject(newestCif.cif));
        setIsInitLoading(false);
      } catch (error) {
        console.log("Error fetching device", error);
      }
    };

    initDevice();
  }, [id]);

  useEffect(() => {
    const subscription = (
      API.graphql(
        graphqlOperation(subscriptions.onDeviceUpdate, { deviceId: id })
      ) as Record<string, any>
    ).subscribe({
      next: (
        response: subscriptions.AmplifySubscriptionResponse<subscriptions.OnDeviceUpdateResponse>
      ) => {
        const device = response.value.data.onDeviceUpdate;
        if (!device) return;
        console.log("Device update received", device);

        setDevice(device);
      },
      error: (error: any) => console.warn(error),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [id]);

  // start subscription for device value changes
  useEffect(() => {
    const subscription = (
      API.graphql(
        graphqlOperation(subscriptions.onCreateDeviceEvent, { deviceId: id })
      ) as Record<string, any>
    ).subscribe({
      next: (
        response: subscriptions.AmplifySubscriptionResponse<subscriptions.OnCreateDeviceEventResponse>
      ) => {
        const event = response.value.data.onCreateDeviceEvent;
        if (!event) return;
        console.log("Device event received", event);

        setMostRecentEvent((mostRecentEvent) => {
          if (
            event &&
            mostRecentEvent &&
            event.eventTime < mostRecentEvent.eventTime
          ) {
            console.error(
              "Received older device event compared to most recent, skipping."
            );
            return mostRecentEvent;
          }
          return event;
        });

        if (event.gnss) {
          setLatestGnssData((prevEvent) => {
            setDeviceRoute((route) => {
              const newPosition = [event.gnss!.longitude, event.gnss!.latitude];
              if (
                (prevEvent?.event.packetCount ?? 0) > event.event.packetCount
              ) {
                if (
                  (prevEvent?.event.packetCount ?? 0) -
                    event.event.packetCount >=
                  10
                )
                  return [newPosition]; // Reset route if count differs by more than 10
                return route;
              }
              return [...route, newPosition];
            });

            return event;
          });
        }

        if (event.cif) {
          setCifData((prevData) => mapCifArrayToObject(event.cif!, prevData));
        }
      },
      error: (error: any) => console.warn(error),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [id]);

  const liveView = (
    <SpaceBetween size="m">
      <Grid
        gridDefinition={[
          {
            colspan: {
              xxs: 12,
              xs: 12,
              s: 5,
              m: 4,
              l: 4,
              xl: 3,
              default: 12,
            },
          },
          {
            colspan: {
              xxs: 12,
              xs: 12,
              s: 7,
              m: 8,
              l: 8,
              xl: 9,
              default: 12,
            },
          },
        ]}
      >
        <DeviceInfo
          device={device}
          latestGnssEvent={latestGnssData}
          latestCifEvent={cifData}
          isLoading={isInitLoading}
        />
        <DeviceMap
          device={device}
          mostRecentEvent={latestGnssData}
          deviceRoute={deviceRoute}
        />
      </Grid>
      <ExpandableSection
        variant="container"
        header={
          <Header
            variant="h2"
            description={
              !device?.connected ? (
                <span className="disconnected">Device is not connected</span>
              ) : undefined
            }
          >
            Real-time metrics
          </Header>
        }
      >
        <SpaceBetween size="m">
          <DeviceEventValuePanel value={mostRecentEvent} />
          <DeviceChart value={mostRecentEvent} />
        </SpaceBetween>
      </ExpandableSection>
    </SpaceBetween>
  );

  const analyticsView = (
    <ExpandableSection
      variant="container"
      defaultExpanded
      header={<Header variant="h2">Device statistics</Header>}
    >
      <QuicksightDashboardEmbed
        parameters={{ deviceId: id }}
        dashboardName="usageByDevice"
      />
    </ExpandableSection>
  );

  return (
    <Box
      id="device-page-container"
      margin={{ bottom: "l", top: "s" }}
      padding="xxs"
    >
      <BreadcrumbGroup
        items={[
          { text: "Fleet View", href: "#" },
          { text: "Device", href: "#device" },
        ]}
        ariaLabel="Breadcrumbs"
      />
      <Header variant="h1">
        {isInitLoading || !device ? (
          "Loading device information..."
        ) : (
          <>
            {device?.deviceId}{" "}
            <span
              className={`status ${
                device.connected ? "connected" : "disconnected"
              }`}
            >
              {device.connected ? "Connected" : "Disconnected"}
            </span>
          </>
        )}
      </Header>
      <Tabs
        className="page-tabs"
        activeTabId={openView}
        onChange={(event) => setOpenView(event.detail.activeTabId)}
        tabs={[
          {
            id: "live",
            label: "Live View",
          },
          {
            id: "analytics",
            label: "Analytics",
          },
        ]}
      />
      <div style={{ display: openView === "live" ? undefined : "none" }}>
        {liveView}
      </div>
      <div style={{ display: openView === "analytics" ? undefined : "none" }}>
        {analyticsView}
      </div>
    </Box>
  );
};

export default DevicePage;
