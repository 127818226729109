import gql from 'graphql-tag';
import { DeviceEvent, Device } from '../types';

export type AmplifySubscriptionResponse<T> = {
  value: {
    data: T
  }
};

export type OnCreateDeviceEventResponse = { onCreateDeviceEvent?: DeviceEvent };
export const onCreateDeviceEvent = /* GraphQL */ gql`
  subscription onCreateDeviceEvent($deviceId: ID!) {
    onCreateDeviceEvent(deviceId: $deviceId) {
      deviceId
      clientId
      eventTime
      expiresAt
      event {
        version
        utc
        id
        infoCode
        packetCount
        powerVoltage
        digitalInputStatus
      }
      gnss {
        utc
        headingDegrees
        latitude
        longitude
        satelliteCount
        hdop
        speedKmh
        maxSpeedKmh
        trip
      }
      usage {
        runningTime
        acceleration
        digitalInput
        mainSupplyVoltage
      }
      cif {
        utc
        id
        data
        dataType
        parsedData
      }
    }
  }
`;

export type OnCreateDeviceEventsResponse = { onCreateDeviceEvents?: DeviceEvent };
export const onCreateDeviceEvents = /* GraphQL */ gql`
  subscription onCreateDeviceEvents {
    onCreateDeviceEvents {
      deviceId
      clientId
      eventTime
      expiresAt
      event {
        version
        utc
        id
        infoCode
        packetCount
        powerVoltage
        digitalInputStatus
      }
      gnss {
        utc
        headingDegrees
        latitude
        longitude
        satelliteCount
        hdop
        speedKmh
        maxSpeedKmh
        trip
      }
      usage {
        runningTime
        acceleration
        digitalInput
        mainSupplyVoltage
      }
      cif {
        utc
        id
        data
        dataType
        parsedData
      }
    }
  }
`;

export type OnDeviceUpdateResponse = { onDeviceUpdate?: Device };
export const onDeviceUpdate = gql`
  subscription onDeviceUpdate($deviceId: ID) {
    onDeviceUpdate(deviceId: $deviceId) {
      deviceId
      clientId
      connected
    }
  }
`;

export type OnDeviceUpdatesResponse = { onDeviceUpdates?: Device };
export const onDeviceUpdates = gql`
  subscription onDeviceUpdates {
    onDeviceUpdates {
      deviceId
      clientId
      connected
    }
  }
`;
