import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import Layout from './components/Layout'
import Routes from './Routes'

import '@aws-amplify/ui-react/styles.css';
import { withAuthenticator } from '@aws-amplify/ui-react';

const App = (): JSX.Element => {
  return (
    <Router>
      <Layout>
        <Routes />
      </Layout>
    </Router>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
