import { DeviceEventCif, Telltale_Key } from "@aplicom-dashboard/common/types";
import {
  AxleWeightMap,
  CifMapping,
  ParsedData,
  TelltaleMap,
} from "../types/DeviceMapTypes";

export const parseNumber = (value: string | number) => {
  if (typeof value === "number") return value;

  const num = parseFloat(value);
  if (isNaN(num)) return undefined;

  return num;
};

export const mapCifArrayToObject = (
  cifArray: DeviceEventCif[],
  previousValues?: CifMapping
) => {
  const telltaleLights = mapTelltaleStatuses(cifArray);
  const axleWeightMap = mapAxleWeights(cifArray);
  const cifData: CifMapping = cifArray
    .filter((cif) => !!cif.dataType)
    .reduce((cifMap: CifMapping, cif) => {
      let parsedData: ParsedData | undefined = undefined;
      switch (cif.dataType) {
        case "TELL_TALE_LIGHTS":
          parsedData = telltaleLights;
          break;
        case "AXLE_WEIGHT":
          parsedData = axleWeightMap;
          break;
        default:
          parsedData = cif.parsedData
            ?.map(parseNumber)
            .filter((n): n is number => Boolean(n));
          break;
      }

      if (!parsedData) return cifMap;

      return {
        ...cifMap,
        [cif.dataType!]: {
          utc: cif.utc,
          parsedData,
        },
      };
    }, {});

  if (!previousValues) return cifData;

  const existingKeys = Object.keys(cifData).filter((key) =>
    Object.keys(previousValues).includes(key)
  ) as (keyof CifMapping)[];

  return {
    ...previousValues,
    ...Object.keys(cifData).reduce((map: CifMapping, key) => {
      const cifKey = key as keyof typeof cifData;
      const contender = cifData[cifKey]!;
      const prev = previousValues[cifKey]!;

      // Set value
      let value = contender;
      if (cifKey === "TELL_TALE_LIGHTS" || cifKey === "AXLE_WEIGHT")
        value = { ...prev, ...contender };

      if (!existingKeys.includes(cifKey)) {
        return {
          ...map,
          [key]: value,
        };
      }

      if (prev.utc > contender.utc) value = prev;

      return {
        ...map,
        [key]: value,
      };
    }, {}),
  };
};

export const mapAxleWeights = (array: DeviceEventCif[]): AxleWeightMap => {
  const axleWeightEvents = array.filter(
    (cif): cif is DeviceEventCif<[string, string]> =>
      cif.dataType === "AXLE_WEIGHT" && !!cif.parsedData
  );
  const parsedMap: AxleWeightMap = axleWeightEvents.reduce(
    (map: AxleWeightMap, { parsedData }) => ({
      ...map,
      [parsedData![0]]: parseNumber(parsedData![1]) ?? 0,
    }),
    {}
  );
  return parsedMap;
};

export const mapTelltaleStatuses = (array: DeviceEventCif[]): TelltaleMap => {
  const telltaleEvents = array.filter(
    (cif): cif is DeviceEventCif<Telltale_Key[]> =>
      cif.dataType === "TELL_TALE_LIGHTS"
  );
  const telltaleValues = telltaleEvents.reduce(
    (array: Telltale_Key[], cif) => [...array, ...(cif?.parsedData ?? [])],
    []
  );

  return telltaleValues.reduce((map: TelltaleMap, telltale) => {
    switch (telltale) {
      case "ADBLUE_LEVEL_YELLOW":
        return {
          ...map,
          "Adblue level": "Yellow",
        };
      case "FUEL_LEVEL_RED":
        return {
          ...map,
          "Fuel level": "Red",
        };
      case "FUEL_LEVEL_YELLOW":
        return {
          ...map,
          "Fuel level": "Yellow",
        };
      case "SERVICE_LIGHT_RED":
        return {
          ...map,
          "Service Light": "Red",
        };
      default:
        return map;
    }
  }, {});
};
